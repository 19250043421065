<template>
    <v-container>
        <!-- <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/> -->
        <ASuccessFour :api="api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary white--text" class="text-h5">
                        Create new step
                    </v-toolbar>
                    <AError :api="api"/>
                    <v-text-field
                    class="mt-5"
                    dense
                    outlined
                    v-model="title"
                    label="Title">

                    </v-text-field>
                    <mavon-editor
                    :toolbars="toolbars"
                    style="z-index:0;min-height:400px;height:auto;width:100%"
                    height="auto"
                    width="auto"
                    min-height="400px"
                    :subfield="false"
                    language="en"
                    v-model="step"
                    placeholder="New step"
                    class="my-5"
                    :editable="true">
                    </mavon-editor>

                    <!-- <v-text-field
                    class="mt-5"
                    label="Step">
                    </v-text-field> -->
                    <v-file-input
                    accept="image/png, image/jpeg "
                    color="primary"
                    prepend-icon="mdi-camera"
                    v-model="attachment"
                    outlined
                    label="Upload image (if have any)">

                    </v-file-input>
                    <div class="d-flex justify-start">
                        <v-text-field v-if="order!=null" v-model="order" dense outlined label="Order">

                        </v-text-field>
                    </div>
                    <div class="d-flex justify-end">
                        <v-btn color="primary" @click="redirectBack" plain>
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="submit" :loading="api.isLoading">
                            Submit
                        </v-btn>
                    </div>

                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import mavonEditor from 'mavon-editor';
import { mapState } from 'vuex'
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import AError from '../../components/common/AError.vue';
export default {
    components:{
    "mavon-editor": mavonEditor.mavonEditor,
    ASuccessFour,
    AError
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        toolbars: {
            bold: true, 
            italic: true, 
            header: true, 
            underline: true,
            strikethrough: true,
            mark: true, 
            superscript: true,
            subscript: true,
            quote: true,
            ol: true, 
            ul: true, 
            link: true, 
            code: true, 
            table: true, 
            fullscreen: true,
            readmodel: true,
            htmlcode: true, 
            help: true, 
            undo: true, 
            redo: true, 
            trash: true,
            navigation: true,
            alignleft: true,
            aligncenter: true,
            alignright: true, 
            subfield: true,
            preview: true, 
        },
        order:null,
        attachment:null,
        title:null,
        step:null,
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:"",
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getLatestManualStep")
            {
                this.order = (resp.data!=null)?parseInt(resp.data.order) +10:10;
                this.api.isLoading=false;
                this.api.isError=false;
                return 1;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.api.isSuccesful = true;
            this.api.success = "Succesfully create a new step";
            return resp.data;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchManualStepMaxOrder(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/step/latest/key/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetch(){
            let fetchManualStepMaxOrderApi = this.fetchManualStepMaxOrder();
            this.$api.fetch(fetchManualStepMaxOrderApi);

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createNewManualSteps = this.create();
            this.$api.fetch(createNewManualSteps);
        },
        cancelSubmit(){
            this.isPending = true;
        },
        create(){
          
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/steps/"+this.$router.history.current.params.id;
            let formData = new FormData;
            formData.append('title',this.title);
            formData.append('step',this.step);
            formData.append('order',this.order);
            if(this.attachment!=null){
                let allowedFiles = ["image/jpeg","image/png"];
                if(!allowedFiles.includes(this.attachment.type)){
                    this.api.isError = true;
                    this.api.error = "Invalid form, only support .png, .jpeg, and .jpg";
                    return 1;
                }
                formData.append('attachmentType','Manual');
                formData.append('attachment',this.attachment);
                formData.append('userEmail',this.$store.getters.getEmail);
            }
            tempApi.params = formData;
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        }
    }
}
</script>